import type Highcharts from 'highcharts';

// adapted from https://www.engie.design/fluid-design-system/data-visualization/charts-resources/#highcharts-engie-light-theme
export const lightTheme: Highcharts.Options = {
  colors: ['#005AA0', '#428054', '#A4853E', '#88DAD8', '#BD5A21', '#B6CDF8', '#0A6361'],
  chart: {
    backgroundColor: 'transparent',
    style: {
      fontFamily: 'Lato, Verdana, sans-serif',
    },
  },
  series: [
    {
      type: 'line',
      lineWidth: 2,
    },
  ],
  title: {
    align: 'left',
    x: -10,
    style: {
      color: '#212121',
      fontSize: '18px',
      fontWeight: '400',
    },
  },
  subtitle: {
    align: 'left',
    y: 36,
    style: {
      color: '#757575',
      fontSize: '16px',
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        color: '#757575',
      },
    },
  },
  xAxis: {
    title: {
      margin: 32,
      style: {
        color: '#212121',
        fontSize: '14px',
      },
    },
    labels: {
      y: 30,
      style: {
        color: '#757575',
        fontSize: '14px',
        fontWeight: '300',
      },
    },
    gridLineColor: '#eeeeee',
    lineColor: '#eeeeee',
    lineWidth: 2,
    tickColor: '#eeeeee',
    tickLength: 8,
    tickWidth: 2,
  },
  yAxis: {
    title: {
      x: -10,
      margin: 32,
      style: {
        color: '#212121',
        fontSize: '14px',
      },
    },
    labels: {
      style: {
        color: '#757575',
        fontSize: '14px',
        fontWeight: '300',
      },
    },
    gridLineColor: '#eeeeee',
    lineColor: '#eeeeee',
  },
  tooltip: {
    backgroundColor: '#0f1e29',
    borderColor: 'none',
    shadow: false,
    borderRadius: 2,
    borderWidth: 0,
    style: {
      color: '#fff',
      fontSize: '12px',
    },
  },
  legend: {
    align: 'left',
    itemStyle: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#757575',
    },
    itemHoverStyle: {
      color: 'gray',
    },
    margin: 32,
  },
  credits: {
    enabled: false,
  },
  navigation: {
    menuStyle: {
      background: 'white',
      border: 'none',
      boxShadow: '0 1px 5px 0 rgba(0,0,0,.1), 0 2px 2px 0 rgba(0,0,0,.06), 0 3px 1px -2px rgba(0,0,0,.06)',
    },
    menuItemStyle: {
      background: 'none',
      color: 'black',
      fontFamily: 'Lato, Verdana, sans-serif',
    },
    menuItemHoverStyle: {
      background: '#F5F5F5',
      color: 'black',
    },
  },
};

export const seriesConfig: Highcharts.SeriesLineOptions = {
  marker: {
    enabled: false,
  },
  data: [],
  type: 'line',
};

export const readingsChartConfig: Highcharts.Options = {
  xAxis: {
    crosshair: false,
    type: 'datetime',
    labels: {
      format: '{value:%H:%M}',
      rotation: -90,
    },
  },
  tooltip: {
    xDateFormat: '%Y-%m-%d',
  },
  legend: {
    enabled: false,
  },
  series: [seriesConfig],
};

export const exportingOptions: Highcharts.ExportingOptions = {
  type: 'image/png',
  filename: 'chart',
  sourceWidth: 1920,
  sourceHeight: 1080,
  scale: 1,
};
