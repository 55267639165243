import type { Site, SiteReading, SiteReport, SiteReports, SiteSummary, SitesUnavailabilities } from '../../types';

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers, reducers } from './reducer';

export interface SiteState {
  loading: boolean;
  sites: Site[];
  sitesReading: SiteReading[];
  siteReport: SiteReport[];
  siteReports: SiteReports;
  siteReportsLoading: boolean;
  siteSummary: SiteSummary | null;
  selectedSites: Site['siteId'][];
  selectedYear: number;
  previousSelectedYear: number;
  selectedSite?: Site['siteId'];
  previousSelectedSite: Site['siteId'];
  sitesUnavailabilitiesLoading: boolean;
  sitesUnavailabilities: SitesUnavailabilities[];
  siteByYearLoading: boolean;
}

const initialState: SiteState = {
  loading: false,
  sites: [],
  sitesReading: [],
  siteReport: [],
  siteReports: [],
  siteReportsLoading: false,
  siteSummary: null,
  selectedSites: [],
  selectedYear: 0,
  previousSelectedYear: 0,
  selectedSite: undefined,
  previousSelectedSite: '',
  sitesUnavailabilitiesLoading: false,
  sitesUnavailabilities: [],
  siteByYearLoading: false,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers,
  extraReducers,
});

export const siteSelector = (state: { site: SiteState }) => state.site;
export const siteReducer = siteSlice.reducer;
export const siteActions = siteSlice.actions;

export const {
  setSiteLoading,
  addOrRemoveSelectedSites,
  setSelectedYear,
  setPreviousSelectedYear,
  setSelectedSite,
  setPreviousSelectedSite,
  setSitesUnavailabilitiesLoading,
} = siteSlice.actions as (typeof siteSlice)['actions'];
