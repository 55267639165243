import { actions as actionsEnum, roles as rolesEnum } from './constant';

export const permissionMap = new Map();

const actions = actionsEnum.enum;
const roles = rolesEnum.enum;

permissionMap.set(actions.ViewDashboard, [roles.CommercialClient]);
permissionMap.set(actions.ViewActivation, [roles.CommercialClient]);
permissionMap.set(actions.ViewCalendar, [roles.CommercialClient]);
permissionMap.set(actions.ViewFinancialStatements, [roles.CommercialClient]);
permissionMap.set(actions.ViewPerformanceReports, [roles.CommercialClient]);
permissionMap.set(actions.ViewContracts, [roles.CommercialClient]);
permissionMap.set(actions.ViewInvoices, [roles.CommercialClient]);
permissionMap.set(actions.ViewReadings, [roles.CommercialClient]);
permissionMap.set(actions.ViewContacts, [roles.CommercialClient]);
permissionMap.set(actions.ViewSite, [roles.CommercialClient]);
