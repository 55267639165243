type Params = Record<string, string | number | boolean | undefined | null>;

export function buildUrl(baseUrl: string, params: Params): string {
  const queryParams = Object.entries(params)
    .filter(([_, value]) => Boolean(value))
    .map(([key, value]) => `${key}=${value!}`)
    .join('&');

  return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
}
