import type { ActionReducerMapBuilder, CreateSliceOptions, PayloadAction } from '@reduxjs/toolkit';
import type { ChartState } from './slice';

import { MeteringSites, MeteringStations } from '../../types';

export const reducers: CreateSliceOptions<ChartState>['reducers'] = {
  setChartLoading: (state, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },
  setStationsReadings: (state, action: PayloadAction<MeteringStations[]>) => {
    state.meteringStations = action.payload;
  },
  setSitesReadings: (state, action: PayloadAction<MeteringSites>) => {
    state.meteringSites = action.payload;
  },
};

export const extraReducers: CreateSliceOptions<ChartState>['extraReducers'] = (builder: ActionReducerMapBuilder<ChartState>) => {
  return builder;
};
