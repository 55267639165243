import { initReactI18next } from 'react-i18next';

import i18next from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import i18nextHTTPBackend from 'i18next-http-backend';

import { activationI18n } from '../modules/activation/i18n';
import { calendarI18n } from '../modules/calendar/i18n';
import { contactsI18n } from '../modules/contacts/i18n';
import { contractsI18n } from '../modules/contracts/i18n';
import { dashboardI18n } from '../modules/dashboard/i18n';
import { financialStatementsI18n } from '../modules/financial-statements/i18n';
import { invoicesI18n } from '../modules/invoices/i18n';
import { performanceReportsI18n } from '../modules/performance-reports/i18n';
import { readingsI18n } from '../modules/readings/i18n';
import { siteI18n } from '../modules/site/i18n';
import { commonI18n } from './common';

export const defaultNS = 'common';
export const resources = {
  en: {
    common: commonI18n.en,
    dashboard: dashboardI18n.en,
    activation: activationI18n.en,
    calendar: calendarI18n.en,
    'financial-statements': financialStatementsI18n.en,
    'performance-reports': performanceReportsI18n.en,
    contracts: contractsI18n.en,
    invoices: invoicesI18n.en,
    contacts: contactsI18n.en,
    site: siteI18n.en,
    reading: readingsI18n.en,
  },
  fr: {
    common: commonI18n.fr,
    dashboard: dashboardI18n.fr,
    activation: activationI18n.fr,
    calendar: calendarI18n.fr,
    'financial-statements': financialStatementsI18n.fr,
    'performance-reports': performanceReportsI18n.fr,
    contracts: contractsI18n.fr,
    invoices: invoicesI18n.fr,
    contacts: contactsI18n.fr,
    site: siteI18n.fr,
    reading: readingsI18n.fr,
  },
} as const;

export const i18n = i18next
  .use(i18nextHTTPBackend)
  .use(i18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development' || process.env.NODE_ENV !== 'test',
    resources,
    defaultNS,
    ns: ['common'],
  });

export default i18next;
