import type {
  FetchReadingsPayload,
  FetchSitesReadingsPayload,
  FetchStationsReadingsPayload,
  MeteringSites,
  MeteringStations,
  TimeSeriesResponse,
} from '../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { buildUrl } from '../../utils/buildUrl';

export const chartApi = createApi({
  reducerPath: 'chartApi',
  baseQuery: fetchBaseQuery({
    headers: {
      'content-type': 'application/json',
    },
  }),
  tagTypes: [],
  endpoints: (builder) => {
    return {
      fetchSiteReadings: builder.query<TimeSeriesResponse[], FetchReadingsPayload>({
        query: ({ siteId, dateRange }) => {
          return {
            url: `${METERING_API}/site/${siteId}/realized?from=${dateRange?.from}&to=${dateRange?.to}`,
            method: 'GET',
          };
        },
      }),
      fetchV3SiteReadings: builder.query<MeteringSites, FetchSitesReadingsPayload>({
        query: ({ siteIds, dateRange, interval, interpolate = 'true', includeRealized = true }) => {
          const baseUrl = `${METERING_API}/v3/sites`;
          const { from, to } = dateRange || {};
          const url = buildUrl(baseUrl, {
            siteIds: siteIds,
            from,
            to,
            interval,
            interpolate,
            includeRealized,
          });

          return {
            url,
            method: 'GET',
          };
        },
      }),
      fetchStationsReadings: builder.query<MeteringStations[], FetchStationsReadingsPayload>({
        query: ({ stationIds, dateRange, intervalInMillis, interpolate = 'true', includeRealized = true }) => {
          const baseUrl = `${METERING_API}/stations`;
          const { from, to } = dateRange || {};
          const url = buildUrl(baseUrl, {
            stationIds,
            from,
            to,
            intervalInMillis,
            interpolate,
            includeRealized,
          });
          return {
            url,
            method: 'GET',
          };
        },
      }),
    };
  },
});

export const METERING_API = 'api/metering';

export const { useFetchSiteReadingsQuery, useLazyFetchSiteReadingsQuery, useLazyFetchStationsReadingsQuery, useLazyFetchV3SiteReadingsQuery } =
  chartApi;
