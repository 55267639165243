import type { CalendarUnavailability } from '../types';

import { differenceInSeconds, endOfDay, format, formatISO, isSameDay, parseISO, startOfDay } from 'date-fns';
import { Locale } from 'date-fns/locale';

import { dateTimeFormatI18n } from './dateTimeFormatter';

export function adjustUnavailabilityData(unavailabilities: CalendarUnavailability[]): CalendarUnavailability[] {
  const adjustedUnavailabilities: CalendarUnavailability[] = [];

  unavailabilities.forEach((unavailability) => {
    const start = new Date(unavailability.declarationStart);
    const end = new Date(unavailability.declarationEnd);

    function earliest(date: Date, end: Date) {
      return date < end ? date : end;
    }

    while (start < end) {
      const newUnavailability = { ...unavailability };
      newUnavailability.declarationStart = formatISO(start);
      newUnavailability.declarationEnd = formatISO(earliest(endOfDay(start), end));
      adjustedUnavailabilities.push(newUnavailability);
      const startOfNextDay = startOfDay(new Date(start).setDate(start.getDate() + 1));
      start.setTime(startOfNextDay.getTime());
    }
  });

  return adjustedUnavailabilities;
}

export function getMonthNames(locale: Locale) {
  const months = [];
  for (let month = 0; month < 12; month++) {
    const date = new Date(0, month); // Year 0 is used to avoid leap year issues
    months.push(format(date, 'MMMM', { locale }));
  }
  return months;
}

export function isFullDay(from: Date, to: Date) {
  const diffInSeconds = differenceInSeconds(to, from);

  return diffInSeconds === 86399;
}

/**
 * Returns the short name of the day (EEE format) for a given date.
 *
 * @param {Date} date - The date for which to get the day name.
 * @param {Locale} [locale] - The locale to use for formatting. Defaults to French locale.
 * @returns {string} The short day name (e.g., 'Mon.', 'Tue.', etc. in English, or 'lun.', 'mar.', etc. in French)
 */
export function getShortDayName(date: Date, locale: Locale): string {
  return format(date, 'EEE', { locale }).toUpperCase();
}

export function splitRangeAcrossDays(currentDay: Date, declarationStart: string, declarationEnd: string) {
  const start = parseISO(declarationStart);
  const end = parseISO(declarationEnd);

  // if start is same as current day take the start else take the current day
  let adjustedStart = isSameDay(start, currentDay) ? start : currentDay;

  // if the end is same as current day take the end else take the end of the current day (23:59:59.999)
  let adjustedEnd = isSameDay(end, currentDay) ? end : endOfDay(currentDay);

  return {
    from: dateTimeFormatI18n({
      date: adjustedStart,
      formatString: 'p',
    }),
    to: dateTimeFormatI18n({
      date: adjustedEnd,
      formatString: 'p',
    }),
  };
}
