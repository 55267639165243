import type { Route } from '../types';

import { actions as actionsEnum } from '../acl';
import { lazyRoute } from '../utils';

const actions = actionsEnum.enum;

export const SITE_PATH = '/site/:siteId';
export const routes = [
  {
    path: '/',
    component: lazyRoute(() => import('../modules/dashboard')),
    can: actions.ViewDashboard,
    label: 'sidebar.dashboard',
    icon: {
      name: 'dashboard',
    },
    sidebarPosition: 0,
    index: true,
  },
  {
    path: '/activations',
    component: lazyRoute(() => import('../modules/activation')),
    can: actions.ViewActivation,
    label: 'sidebar.activation',
    icon: {
      name: 'bolt',
    },
    sidebarPosition: 1,
  },
  {
    path: '/calendar',
    component: lazyRoute(() => import('../modules/calendar')),
    can: actions.ViewCalendar,
    label: 'sidebar.calendar',
    icon: {
      name: 'calendar_today',
    },
    sidebarPosition: 2,
  },
  {
    path: '/financial-statements',
    component: lazyRoute(() => import('../modules/financial-statements')),
    can: actions.ViewFinancialStatements,
    label: 'sidebar.financial-statements',
    icon: {
      name: 'account_balance',
    },
    sidebarPosition: 3,
  },
  // {
  //   path: '/performance-reports',
  //   component: lazyRoute(() => import('../modules/performance-reports')),
  //   can: actions.ViewPerformanceReports,
  //   label: 'sidebar.performance-reports',
  //   icon: {
  //     name: 'bar_chart',
  //   },
  //   sidebarPosition: 4,
  // },
  {
    path: '/readings',
    component: lazyRoute(() => import('../modules/readings')),
    can: actions.ViewReadings,
    label: 'sidebar.readings',
    icon: {
      name: 'ssid_chart',
    },
    sidebarPosition: 4,
  },
  {
    path: '/contracts',
    component: lazyRoute(() => import('../modules/contracts')),
    can: actions.ViewContracts,
    label: 'sidebar.contracts',
    icon: {
      name: 'assignment',
    },
    sidebarPosition: 5,
  },
  // {
  //   path: '/invoices',
  //   component: lazyRoute(() => import('../modules/invoices')),
  //   can: actions.ViewInvoices,
  //   label: 'sidebar.invoices',
  //   icon: {
  //     name: 'receipt_long',
  //   },
  //   sidebarPosition: 6,
  // },
  {
    path: '/contacts',
    component: lazyRoute(() => import('../modules/contacts')),
    can: actions.ViewContacts,
    label: 'sidebar.contacts',
    icon: {
      name: 'person',
    },
    sidebarPosition: 7,
  },
  {
    path: SITE_PATH,
    component: lazyRoute(() => import('../modules/site')),
    can: actions.ViewSite,
  },
] satisfies Route[];
