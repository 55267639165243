import { z } from 'zod';

export const roles = z.enum(['Operations', 'Client', 'CommercialClient', 'Engineer', 'Support', 'Admin', 'SwitchPartner'], {
  description: 'User roles',
});

export const actions = z.enum(
  [
    'ViewDashboard',
    'ViewActivation',
    'ViewCalendar',
    'ViewFinancialStatements',
    'ViewPerformanceReports',
    'ViewContracts',
    'ViewInvoices',
    'ViewContacts',
    'ViewReadings',
    'ViewSite',
  ],
  {
    description: 'User actions',
  }
);
