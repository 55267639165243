import type { ActionReducerMapBuilder, CreateSliceOptions, PayloadAction } from '@reduxjs/toolkit';
import type { Site } from '../../types';
import type { SiteState } from './slice';

import { siteApi } from './service';

/** Reducers for site slice */
export const reducers: CreateSliceOptions<SiteState>['reducers'] = {
  setSiteLoading: (state, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },
  addOrRemoveSelectedSites: (state, action: PayloadAction<Site['siteId'][]>) => {
    state.selectedSites = action.payload;
  },
  setSelectedYear: (state, action: PayloadAction<number>) => {
    state.selectedYear = action.payload;
  },
  setPreviousSelectedYear: (state, action: PayloadAction<number>) => {
    state.previousSelectedYear = action.payload;
  },
  setSelectedSite: (state, action: PayloadAction<Site['siteId']>) => {
    state.selectedSite = action.payload;
  },
  setPreviousSelectedSite: (state, action: PayloadAction<Site['siteId']>) => {
    state.previousSelectedSite = action.payload;
  },
  setSitesUnavailabilitiesLoading: (state, action: PayloadAction<boolean>) => {
    state.sitesUnavailabilitiesLoading = action.payload;
  },
} as const;

/**
 * Extra reducers for site slice
 *
 * @param builder ActionReducerMapBuilder<SiteState>
 */
export const extraReducers: CreateSliceOptions<SiteState>['extraReducers'] = (builder: ActionReducerMapBuilder<SiteState>) => {
  return builder
    .addMatcher(siteApi.endpoints.fetchSites.matchFulfilled, (state, { payload }) => {
      state.sites = payload;
    })
    .addMatcher(siteApi.endpoints.fetchAllSites.matchFulfilled, (state, { payload }) => {
      state.sitesReading = payload;
    })
    .addMatcher(siteApi.endpoints.fetchSiteReport.matchPending, (state) => {
      state.siteReport = [];
    })
    .addMatcher(siteApi.endpoints.fetchSiteReport.matchFulfilled, (state, { payload }) => {
      state.siteReport.push(payload);
    })
    .addMatcher(siteApi.endpoints.fetchSiteReports.matchPending, (state) => {
      state.siteReports = [];
    })
    .addMatcher(siteApi.endpoints.fetchSiteReports.matchFulfilled, (state, { payload }) => {
      payload.forEach((report) => {
        state.siteReports.push(report);
      });
    })
    .addMatcher(siteApi.endpoints.fetchUnavailabilityPerDate.matchFulfilled, (state, { payload }) => {
      state.sitesUnavailabilities = payload;
    });
};
