import { createSlice } from '@reduxjs/toolkit';

import { MeteringSites, MeteringStations } from '../../types';
import { extraReducers, reducers } from './reducer';

export interface ChartState {
  loading: boolean;
  meteringStations: MeteringStations[];
  meteringSites: MeteringSites;
}

const initialState: ChartState = {
  loading: false,
  meteringStations: [],
  meteringSites: [],
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers,
  extraReducers,
});

export const chartSelector = (state: { chart: ChartState }) => state.chart;
export const chartReducer = chartSlice.reducer;

export const { setChartLoading, setStationsReadings, setSitesReadings } = chartSlice.actions;
